import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useState, useEffect } from 'react';
import GeneralErrorContainer from '../Containers/GeneralErrorContainer/GeneralErrorContainer';

export interface QueryValidation {
  paramValid: boolean;
  missingParams: Array<string>;
  invalidParams: Array<string>;
  initialUrl?: string;
}

function checkParamValidity(params: querystring.ParsedQuery): QueryValidation {
  let paramValid = true;
  const missingParams: Array<string> = [];
  const invalidParams: Array<string> = [];

  if (!params.schemaname) {
    missingParams.push('schemaname');
    paramValid = false;
    return { paramValid, missingParams, invalidParams };
  } else if (params.schemaname.length === 0) {
    invalidParams.push('schemaname');
    paramValid = false;
    return { paramValid, missingParams, invalidParams };
  }

  return { paramValid, missingParams, invalidParams };
}

interface ParamGuardProps {
  children: JSX.Element;
}

export default function ParamGuard({ children }: ParamGuardProps) {
  const location = useLocation();
  const [validationObject, setValidationObject] = useState<QueryValidation>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location && location.search) {
      const parsedSearch = querystring.parse(location.search.toLowerCase());
      const validationPayload = checkParamValidity(parsedSearch);
      if (window && window.location && window.location.href) {
        setValidationObject({
          ...validationPayload,
          initialUrl: window.location.href,
        });
      } else {
        setValidationObject(validationPayload);
      }
    }
    setLoading(false);
  }, []);

  return validationObject && validationObject.paramValid ? (
    children
  ) : (
    <GeneralErrorContainer
      errorMessage="Parameters not found!"
      errorDescription="Looks like the necessary parameters were not passed to the URL or were not valid. Make sure to have the right navigation link when trying to access this page."
      validation={validationObject}
      loading={loading}
    />
  );
}
