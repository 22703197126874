import { LoginResult } from '@myosh/myosh-login/dist/types/components/login.component';
import { useState, useEffect } from 'react';
import { Route, useLocation, Routes, useNavigate } from 'react-router-dom';
import querystring from 'query-string';
import { LoginCallback, LogoutCallback, Login } from '@myosh/myosh-login';
import PrivateRoute from './shared/PrivateRoute';
import NoMatchRoute from './shared/NoMatchRoute';
import Main from '../../views/main/Main';
import UsernameGuard from '../../guards/UsernameGuard';
import ParamGuard from '../guards/ParamGuard';

const GuardedMain = () => (
  <UsernameGuard>
    <Main />
  </UsernameGuard>
);

export default function RoutesComponent() {
  const [username, setUsername] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();

  const onLoginSuccess = (results: LoginResult) => {
    if (results) {
      localStorage.setItem('idToken', results.idToken);
      localStorage.setItem('refreshToken', results.refreshToken);
      localStorage.setItem('schema', results.selectedSchema);
    }

    const storedInitialParams = localStorage.getItem('initialParams');
    navigate(`/${storedInitialParams ? storedInitialParams : ''}`);
  };

  useEffect(() => {
    if (location && location.search) {
      const parsedQuery = querystring.parse(location.search);
      if (parsedQuery && Object.keys(parsedQuery).length > 0) {
        if (parsedQuery.userName) {
          setUsername(parsedQuery.userName as string);
        }
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/auth/cb/logout" element={<LogoutCallback />} />
        <Route path="/auth/cb/silent" element={<div>Silent renewal callback</div>} />
        <Route path="/auth/cb" element={<LoginCallback />} />
        <Route path="/login" element={<Login onLoginSuccess={onLoginSuccess} username={username} />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route
            index
            element={
              <ParamGuard>
                <GuardedMain />
              </ParamGuard>
            }
          />
        </Route>
        <Route path="*" element={<NoMatchRoute />} />
      </Routes>
    </>
  );
}
