import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
  isBowtieCauseData,
  isBowtieConsequenceData,
  isErrorResponse,
  isSuccessResponse,
} from '../../common/type-guards';
import { BowtieStateData } from '../../services/bowtie-data-types';
import { RecordResult } from '../../services/record-data-types';
import { RecordService } from '../../services/record-service';
import RecordServiceJS from './diagramService';
import {
  DIAGRAM_LAYOUT,
  DIAGRAM_UNLINK_CONTROL_FAIL,
  DIAGRAM_UNLINK_CONTROL_START,
  DIAGRAM_UNLINK_CONTROL_SUCCESS,
} from './diagramTypes';
import { RootState } from '../hooks';

interface WarningToastNotificationProps {
  message: string;
}

const WarningToastNotification = ({ message }: WarningToastNotificationProps) => {
  return (
    <div className="d-flex flex-row py-3 px-2 align-items-center w-100">
      <p className="mb-0" style={{ lineHeight: '1.2rem' }}>
        {message}
      </p>
    </div>
  );
};

interface ControlPayload {
  id: number;
  uuid: number;
  parentId: number;
  value: string;
  status: string;
  causes?: Array<RecordResult>;
  consequences?: Array<RecordResult>;
}

const recordServiceJS = new RecordServiceJS('test');
const recordService = new RecordService('test');

// NOTE: temporarily set to 'any' as this needs to be rewritten to use a slice if needed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unlinkControl = (payload: ControlPayload) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  try {
    const globalState = getState();
    const bowtieData = (globalState.diagram as { bowtieData: BowtieStateData }).bowtieData;
    const { scenarioRecord, causes, consequences } = bowtieData;

    const scenarioMitigatingControlIds = (scenarioRecord.fields['Mitigating Controls'] as Array<string>) ?? [];
    const scenarioPreventativeControlIds = (scenarioRecord.fields['Preventative Controls'] as Array<string>) ?? [];
    const isPreventative = scenarioPreventativeControlIds.includes(String(payload.id));

    // not a huge fan of this, but it will do for now
    let controlCount = 0;
    if (isPreventative) {
      if (causes) {
        for (let i = 0, iLength = causes.length || 0; i < iLength; i++) {
          const cause = causes[i];
          for (let j = 0, jLength = cause.preventativeControls.length; j < jLength; j++) {
            if (cause.preventativeControls[j].id === payload.id) {
              controlCount++;
            }
          }
        }
      }
    } else {
      if (consequences) {
        for (let i = 0, iLength = consequences.length || 0; i < iLength; i++) {
          const consequence = consequences[i];
          for (let j = 0, jLength = consequence.mitigatingControls.length; j < jLength; j++) {
            if (consequence.mitigatingControls[j].id === payload.id) {
              controlCount++;
            }
          }
        }
      }
    }

    const controlPayload = await recordService.fetchRecord(payload.id);

    if (isErrorResponse(controlPayload)) {
      dispatch({
        type: DIAGRAM_UNLINK_CONTROL_FAIL,
        payload: `A control with id ${payload.id} does not exist`,
      });
      return;
    }

    const controlParentPayload = isPreventative
      ? causes?.find((cause) => cause.id === payload.parentId)
      : consequences?.find((consequence) => consequence.id === payload.parentId);

    let controlParentIndex: string | undefined;
    if (isBowtieCauseData(controlParentPayload)) {
      if (controlParentPayload.preventativeControls.length === 0) {
        controlParentIndex = `standaloneCauses_${window.location.search}`;
      }
    } else if (isBowtieConsequenceData(controlParentPayload)) {
      if (controlParentPayload.mitigatingControls.length === 0) {
        controlParentIndex = `standaloneConsequences_${window.location.search}`;
      }
    }

    if (controlParentIndex) {
      const storedStandaloneControlParents = localStorage.getItem(controlParentIndex);

      localStorage.setItem(
        controlParentIndex,
        storedStandaloneControlParents
          ? JSON.stringify([...JSON.parse(storedStandaloneControlParents), controlParentPayload])
          : JSON.stringify([controlParentPayload])
      );
    }

    dispatch({
      type: DIAGRAM_UNLINK_CONTROL_START,
      payload: bowtieData,
    });
    dispatch({
      type: DIAGRAM_LAYOUT,
      payload: {
        isWidth: true,
      },
    });

    toast(<WarningToastNotification message={`Unlinking: '${payload.value}' - please wait...`} />);

    if (controlCount === 1) {
      await recordServiceJS.doUpdateRecord(scenarioRecord.id, {
        id: scenarioRecord.id,
        formId: scenarioRecord.formId,
        fields: isPreventative
          ? {
              'Preventative Controls': scenarioPreventativeControlIds.filter((id) => id !== String(payload.id)),
            }
          : {
              'Mitigating Controls': scenarioMitigatingControlIds.filter((id) => id !== String(payload.id)),
            },
        hierarchies: {},
      });
    }

    const controlRecord = controlPayload.payload.data.result.results[0];
    const controlUpdatePayload = await recordService.unlinkControl(
      String(controlRecord.id),
      controlRecord.formId,
      controlRecord.moduleId as number,
      payload.parentId,
      controlRecord,
      isPreventative
    );

    if (isSuccessResponse(controlUpdatePayload)) {
      if (isPreventative) {
        bowtieData.causes = bowtieData.causes?.map((cause) => {
          if (cause.id === payload.parentId) {
            cause.preventativeControls = cause.preventativeControls.filter((control) => control.id !== payload.id);
            return cause;
          }

          return cause;
        });
      } else {
        bowtieData.consequences = bowtieData.consequences?.map((consequence) => {
          if (consequence.id === payload.parentId) {
            consequence.mitigatingControls = consequence.mitigatingControls.filter(
              (control) => control.id !== payload.id
            );
            return consequence;
          }

          return consequence;
        });
      }

      toast(<WarningToastNotification message={`Control: '${payload.value}' successfully unlinked.`} />);

      dispatch({
        type: DIAGRAM_UNLINK_CONTROL_SUCCESS,
        payload: bowtieData,
      });
    } else {
      dispatch({
        type: DIAGRAM_UNLINK_CONTROL_FAIL,
        payload: controlUpdatePayload.payload,
      });
    }
  } catch (error) {
    dispatch({
      type: DIAGRAM_UNLINK_CONTROL_FAIL,
      payload: (error as Error).message,
    });
  }
};

const controlActions = {
  unlinkControl,
};

export default controlActions;
