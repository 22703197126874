import './GeneralContainer.css';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../modules/hooks';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';

const ids = {
  causes: 'causes-container',
  consequences: 'consequences-container',
  mitigatingControls: 'mitigating-controls-container',
  preventativeControls: 'preventative-controls-container',
};

export default function GeneralContainer(props) {
  const { title, backgroundColor, id, children } = props;

  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);

  const hasDraftRecord = useMemo(() => {
    let generalForm = null;
    let records = null;

    switch (id) {
      case ids.causes: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.causes?.form;
        records = bowtieData?.causes;
        break;
      }
      case ids.consequences: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.consequences?.form;
        records = bowtieData?.consequences;
        break;
      }
      case ids.mitigatingControls: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.controls?.form;
        records = bowtieData?.consequences?.map((consequence) => consequence.mitigatingControls)?.flat();
        break;
      }
      case ids.preventativeControls: {
        generalForm = bowtieData?.bowtieConfiguration?.forms?.controls?.form;
        records = bowtieData?.causes?.map((cause) => cause.preventativeControls)?.flat();
        break;
      }
      default: {
        generalForm = null;
        records = null;
      }
    }

    const formWorkflowSteps = generalForm && generalForm.workflowSteps && generalForm.workflowSteps;

    const draftWorkflowStep = formWorkflowSteps && formWorkflowSteps.find((workflowStep) => workflowStep.draftMarker);

    const draftWorkflowStepLabel =
      draftWorkflowStep &&
      draftWorkflowStep.label.translations &&
      draftWorkflowStep.label.translations[0] &&
      draftWorkflowStep.label.translations[0].value;

    return records && records.some((record) => record.status === draftWorkflowStepLabel || record.status === 'Draft');
  }, [bowtieData, id]);

  const generalContainerStyles = {
    padding: '18px 30px',
    fontFamily: 'Roboto',
    textAlign: 'center',
    backgroundColor,
    marginRight: '1rem',
    position: 'relative',
    color: '#333',
    height: 'max-content',
  };

  return (
    <div className="general-area">
      <p className="draft-header" style={{ visibility: !hasDraftRecord && 'hidden' }}>
        Draft
      </p>
      <div className="general-container button-radius" id={id} style={{ ...generalContainerStyles }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: '4rem' }}>{title}</h4>
        {children}
      </div>
    </div>
  );
}

GeneralContainer.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  id: PropTypes.string,
};
