import React from 'react';
import './GeneralErrorContainer.css';
import { QueryValidation } from '../../guards/ParamGuard';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import ErrorIcon from '../../../assets/images/error.png';

interface GeneralErrorContainerProps {
  errorMessage: string;
  errorDescription: string;
  loading?: boolean;
  validation?: QueryValidation;
}

export default function GeneralErrorContainer({
  errorMessage,
  errorDescription,
  validation,
  loading,
}: GeneralErrorContainerProps) {
  if (loading) {
    return null;
  }

  return validation && Object.keys(validation).length > 0 ? (
    <div className="container">
      <img src={ErrorIcon} alt="missing_info" className="icon" />
      {validation.missingParams && validation.missingParams.length > 0 ? (
        <h3 className="infoHeader">
          {validation.missingParams.map((missingParam) => `'${missingParam}'`).join(' , ')}{' '}
          {validation.missingParams.length > 1 ? 'parameters' : 'parameter'} not found!
        </h3>
      ) : validation.invalidParams && validation.invalidParams.length > 0 ? (
        <h3 className="infoHeader">
          {validation.invalidParams.map((invalidParam) => `'${invalidParam}'`).join(' , ')}{' '}
          {validation.missingParams.length > 1 ? 'parameters are ' : 'parameter is '} invalid!
        </h3>
      ) : (
        <h3 className="infoHeader">Parameters not found!</h3>
      )}
      <p className="infoText">
        Looks like the necessary required parameters were not passed to the URL or were not valid. Make sure to have the
        right navigation link when trying to access this page.
      </p>
      {validation.initialUrl && validation.initialUrl.length > 0 && (
        <p className="infoText" style={{ whiteSpace: 'pre-wrap' }}>
          The navigation link you provided is: <br />
          <i title={validation.initialUrl}>
            {validation.initialUrl.length > 100 ? validation.initialUrl.slice(0, 100) + '...' : validation.initialUrl}
          </i>
        </p>
      )}
      <p className="infoText">
        The navigation link should have the following structure:{' '}
        <i>
          https://bowtie-base-url.com?schemaName=
          <span>[validSchemaName]</span>&records=
          <span>[validRecordId (Numeric)]</span>
        </i>
      </p>
    </div>
  ) : (
    <div className="container">
      <img src={ErrorIcon} alt="missing_info" className="icon" />
      {errorMessage && <h3 className="infoHeader">{errorMessage}</h3>}
      {errorDescription && <p className="infoText">{errorDescription}</p>}
    </div>
  );
}
