import { palettes } from '../environment/environment';

const env = process.env;

export interface EndPointSettings {
  urlTemplate: string;
  endpointBaseUrl?: string;
  apiKey?: string;
}

export const keys: Record<string, EndPointSettings> = {
  test: {
    urlTemplate: ':endpointBaseUrl/:endpointVersion',
    endpointBaseUrl: env.REACT_APP_API_V4,
    apiKey: env.REACT_APP_API_KEY,
  },
};

export const savingStyle = {
  borderStyle: 'solid',
  color: 'rgba(51, 51, 51, 0.5)',
  borderColor: 'rgba(51, 51, 51, 0.5)',
};

export const defaultStyle = {
  borderStyle: 'dashed',
  color: palettes.text.primary,
  borderColor: palettes.criticalControl.primary,
};
