import { configureStore } from '@reduxjs/toolkit';

import diagramReducer from './diagram/diagramReducer';
import filterReducer from './filter/filterReducer';
import userReducer from './user/userReducer';

const store = configureStore({
  reducer: {
    diagram: diagramReducer,
    filter: filterReducer,
    user: userReducer,
  },
});

export default store;
