import { createSelector } from 'reselect';

const selectRaw = (state) => state.filter;

const selectCriticalFilter = createSelector([selectRaw], (filter) => filter.criticalFilter);

const selectNonEffectiveFilter = createSelector([selectRaw], (filter) => filter.nonEffectiveFilter);

const selectCriticalFilterValues = createSelector([selectRaw], (filter) => filter.criticalFilterValues);

const selectNonEffectiveFilterValues = createSelector([selectRaw], (filter) => filter.nonEffectiveFilterValues);

const selectDiagramMode = createSelector([selectRaw], (filter) => filter.diagramMode);

const filterSelectors = {
  selectCriticalFilter,
  selectNonEffectiveFilter,
  selectCriticalFilterValues,
  selectNonEffectiveFilterValues,
  selectDiagramMode,
};

export default filterSelectors;
