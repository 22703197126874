interface LinkIconProps {
  width: number;
  height: number;
  color: string;
}

export default function LinkIcon({ width, height, color }: LinkIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="5.625" fill="#F0F0F0" stroke={color} strokeWidth="0.75" />
      <path
        d="M5.15461 4.0423V4.93572C5.15461 5.09369 5.09186 5.24518 4.98016 5.35688C4.86846 5.46858 4.71696 5.53133 4.559 5.53133H1.28311C1.12515 5.53133 0.973651 5.46858 0.861951 5.35688C0.750252 5.24518 0.6875 5.09369 0.6875 4.93572V1.65984C0.6875 1.50187 0.750252 1.35038 0.861951 1.23868C0.973651 1.12698 1.12515 1.06422 1.28311 1.06422H2.17654"
        stroke={color}
        fill="#F0F0F0"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3, 3)"
      />
      <path
        d="M4.40217 1.18573L5.03293 1.81619L4.40217 1.18573ZM4.80778 0.630316L3.10224 2.33586C3.01412 2.42386 2.95402 2.53598 2.92951 2.65809L2.77197 3.44668L3.56057 3.28884C3.68267 3.26442 3.79464 3.20456 3.88279 3.11641L5.58834 1.41087C5.63959 1.35962 5.68024 1.29877 5.70798 1.23181C5.73572 1.16485 5.74999 1.09307 5.74999 1.02059C5.74999 0.948112 5.73572 0.87634 5.70798 0.809377C5.68024 0.742413 5.63959 0.681568 5.58834 0.630316C5.53709 0.579064 5.47624 0.538409 5.40928 0.510672C5.34231 0.482935 5.27054 0.468658 5.19806 0.468658C5.12558 0.468658 5.05381 0.482935 4.98684 0.510672C4.91988 0.538409 4.85904 0.579064 4.80778 0.630316V0.630316Z"
        stroke={color}
        fill="#F0F0F0"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3, 3)"
      />
    </svg>
  );
}
